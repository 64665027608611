import React, { Component } from 'react'


const BillingHistory = () =>{

  return (
    <>
    <h1 className="text-white">BillingHistory</h1>
    </>
  )
}

export default BillingHistory;
