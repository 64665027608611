import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black text-white text-sm py-4 mt-0">
      <div className="container mx-auto px-4">
        <div className="flex justify-center space-x-6 mb-4">
          <a href="/terms" className="hover:text-white">
            Terms and Conditions
          </a>
          <span className="text-gray-500">•</span>
          <a href="/terms" className="hover:text-white">
            Privacy Policy
          </a>
          <span className="text-gray-500">•</span>
          <a href="/terms" className="hover:text-white">
            Refund Policy
          </a>
        </div>
        <div className="text-center mb-4">
          Address: Flat-no: 1225,gemni paradise, Moglaha, Gorakhpur, 273013,
          India
          <br />
          <br />
          EmainID : support@baremin.xyz
        </div>
      </div>
    </footer>
  );
};

export default Footer;
