// import React,{useState} from "react";
// import TERMS from "./consts/tnc";
// import PRIVACY from "./consts/privacy";

// const TandC = () => {
//  // State to manage active tab and content
//   const [activeTab, setActiveTab] = useState("terms");

//   // Content for each tab
//   const content = {
//     terms: TERMS,
//     privacy: PRIVACY,
//     refund: "If a user is dissatisfied with the quality of generated content, they may raise a refund request by contacting the support email provided in the page footer. Refunds will be issued as platform credits in 7 working days, which are strictly non-refundable. These credits can only be utilized for content generation services within the platform and cannot be redeemed for cash or transferred externally.",
//   };


//     return (
//        <div className="flex h-full bg-white">
//         <span classNamew="text-white"></span>
//       {/* Sidebar */}
//       <div className="w-1/4 bg-blue-50 p-4 border-r border-white">
//         <ul className="space-y-4">
//           <li>
//             <button
//               onClick={() => setActiveTab("terms")}
//               className={`block w-full text-left py-2 px-4 rounded-md ${
//                 activeTab === "terms"
//                   ? "bg-blue-500 text-white"
//                   : "text-gray-700 hover:bg-blue-100"
//               }`}
//             >
//               Terms and Conditions
//             </button>
//           </li>
//           <li>
//             <button
//               onClick={() => setActiveTab("privacy")}
//               className={`block w-full text-left py-2 px-4 rounded-md ${
//                 activeTab === "privacy"
//                   ? "bg-blue-500 text-white"
//                   : "text-gray-700 hover:bg-blue-100"
//               }`}
//             >
//               Privacy Policy
//             </button>
//           </li>
//           <li>
//             <button
//               onClick={() => setActiveTab("refund")}
//               className={`block w-full text-left py-2 px-4 rounded-md ${
//                 activeTab === "refund"
//                   ? "bg-blue-500 text-white"
//                   : "text-gray-700 hover:bg-blue-100"
//               }`}
//             >
//               Refund Policy
//             </button>
//           </li>
//         </ul>
//       </div>

//       {/* Content Area */}
//       <div className="w-3/4 p-6">
//         <h1 className="text-2xl font-bold mb-4">
//           {activeTab === "terms" && "Terms and Conditions"}
//           {activeTab === "privacy" && "Privacy Policy"}
//           {activeTab === "refund" && "Refund Policy"}
//         </h1>
//         <p className="text-gray-600">{content[activeTab]}</p>
//       </div>
//     </div>
//   );
// }

// export default TandC
//Amritesh Fix
import React,{useState} from "react";
import TERMS from "./consts/tnc";
import PRIVACY from "./consts/privacy";

const TandC = () => {
 // State to manage active tab and content
  const [activeTab, setActiveTab] = useState("terms");

  // Content for each tab
  const content = {
    terms: TERMS,
    privacy: PRIVACY,
    refund: "If a user is dissatisfied with the quality of generated content, they may raise a refund request by contacting the support email provided in the page footer. Refunds will be issued as platform credits in 7 working days, which are strictly non-refundable. These credits can only be utilized for content generation services within the platform and cannot be redeemed for cash or transferred externally.",
  };


    return (
       <div className="flex h-full bg-white">
        <span classNamew="text-white"></span>
      {/* Sidebar */}
      <div className="w-1/4 bg-blue-50 p-4 border-r border-white">
        <ul className="space-y-4">
          <li>
            <button
              onClick={() => setActiveTab("terms")}
              className={`block w-full text-left py-2 px-4 rounded-md ${
                activeTab === "terms"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-blue-100"
              }`}
            >
              Terms and Conditions
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("privacy")}
              className={`block w-full text-left py-2 px-4 rounded-md ${
                activeTab === "privacy"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-blue-100"
              }`}
            >
              Privacy Policy
            </button>
          </li>
          <li>
            <button
              onClick={() => setActiveTab("refund")}
              className={`block w-full text-left py-2 px-4 rounded-md ${
                activeTab === "refund"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-blue-100"
              }`}
            >
              Refund Policy
            </button>
          </li>
        </ul>
      </div>

      {/* Content Area */}
      <div className="w-3/4 p-6">
        <h1 className="text-2xl font-bold mb-4">
          {activeTab === "terms" && "Terms and Conditions"}
          {activeTab === "privacy" && "Privacy Policy"}
          {activeTab === "refund" && "Refund Policy"}
        </h1>
        <pre className="text-gray-600 whitespace-pre-wrap font-sans leading-relaxed max-w-full overflow-x-auto p-4">
          {content[activeTab].split('**').map((text, index) => 
            index % 2 === 0 ? text : <strong key={index}>{text}</strong>
          )}
        </pre>
      </div>
    </div>
  );
}

export default TandC
