// src/LandingPage.js
import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import  Dasboard  from './Dasboard'
import {House,CreditCard,User,Lightning} from 'phosphor-react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LandingPage = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("home");
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleSlideClick=(index)=> {
  if(index === 1){
    navigate('/removebg');
  }
  if(index === 2){
    navigate('/upload');
  }
  if(index === 3){
    navigate('/logoanywhere');
  }
  if(index === 4){
    navigate('/landing');
  }
  if(index === 5){
    navigate('/landing');
  }
}

  const goToBillingPage = async () => {
    navigate('/billing');
  }

  const goToAccountPage = async () => {
    navigate('/account');
  }

  const goToHomePage = async () => {
    navigate('/landing');
  }

  const data = [
  {
    img: `./assets/removeBg-1.png`
  },
  {
     img: `./assets/Interior-1.png`,
  },
  {
    img: `./assets/LogoAnywhere.webp`
  },
  {
    img: `./assets/comingsoon-1.png`
  },
  {
    img: `./assets/comingsoon-1.png`
  },
];

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-center mt-8 mb-16">
        <span className="text-gray-800 text-6xl text-center max-w-4xl">
          What are we making today?
        </span>
      </div>
      <div className="mt-8">
        <div className="max-w-5xl mx-auto">
          <div className="carousel-container">
            <Slider {...settings}>
              {data.map((d, index) => (
                <div key={index} className="carousel-item">
                  <div className="bg-white rounded-xl hover:opacity-60 mx-2">
                    <img 
                      src={d.img} 
                      alt="" 
                      className="hover:cursor-pointer rounded-xl"
                      onClick={() => handleSlideClick(index+1)}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <nav className="w-auto bg-black py-4 px-8 rounded-3xl mb-8 mt-16">
          <div className="flex justify-around max-w-xl mx-auto text-white">
            <div className={`flex flex-col items-center border-r-2 pr-8 cursor-pointer ${
                selected === "home" ? "text-yellow-400" : "hover:text-gray-500"
              }
              `} onClick={() => {setSelected("home");goToHomePage()}}>
              <House size={24}  />
              <span className="text-xs">Home</span>
            </div>
            <div className={`flex flex-col items-center border-r-2 px-8 cursor-pointer ${
                selected === "tools" ? "text-yellow-400" : "hover:text-gray-500"
              }`} onClick={() => {setSelected("tools");}}>
              <Lightning size={24} />
              <span className="text-xs">Tools</span>
            </div>
            <div className={`flex flex-col items-center border-r-2 px-8 cursor-pointer ${
                selected === "billing" ? "text-yellow-400" : "hover:text-gray-500"
              }`} onClick={() => {setSelected("billing");goToBillingPage()}}>
              <CreditCard size={24} />
              <span className="text-xs">Billing</span>
            </div>
            <div className={`flex flex-col items-center cursor-pointer pl-8 ${
                selected === "account" ? "text-yellow-400" : "hover:text-gray-500"
              }
              `} onClick={() => {setSelected("account");goToAccountPage()}}>
              <User size={24}  />
              <span className="text-xs">Account</span>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default LandingPage;