import React, { useState } from 'react';
import { useAuth } from '../AuthProvider';
import { addCredits } from '../firebase/creditService';
import creditsService from '../firebase/globalCreditsService';

const serviceBaseUrl = process.env.REACT_APP_API_SERVICE_53;

const AddCredits = () =>{
  const [amount, setAmount] = useState('');
  const { currentUser, setCredits } = useAuth();

  const handlePaymentSuccess = async (response) => {
    // Calculate credits based on amount (example: 1 INR = 1 credit)
    const creditsToAdd = parseInt(amount);
    const newCredits = await addCredits(currentUser.uid, creditsToAdd);
    if (newCredits) {
      console.log(creditsService.getCredits());
      alert('Payment successful! Credits added to your account.', creditsService.getCredits());
    }
  };

  async function openRazorPay() {
      // Create order by calling the server endpoint
      const response = await fetch(`${serviceBaseUrl}/create-order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
        body: JSON.stringify({ amount, currency: 'INR', receipt: 'receipt#1', notes: {} })
    });

    const order = await response.json();
      // Open Razorpay Checkout
    const options = {
        key: 'rzp_live_MnW8FvAZYjHUyz', // Replace with your Razorpay key_id
        amount: order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: order.currency,
        name: 'BareMin',
        description: 'Add credits',
        order_id: order.id, // This is the order_id created in the backend
        handler: handlePaymentSuccess,
      prefill: {
        name: currentUser.displayName,
        email: currentUser.email,
        contact: '9999999999'
      },
      theme: {
          color: 'black'
        },
    };

      // const rzp = new Razorpay(options);
      // rzp.open();
       const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }
  return (
    <>
     <div className="flex justify-between items-center space-x-2">
      {/* Input Field */}
      <input
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        type="number"
        placeholder="Enter Amount"
        className="w-7/12 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
      {/* Button */}
      <button
        id="addCreditsButton"
        className="px-6 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500" onClick={openRazorPay}>
        Add Credits
      </button>
    </div>
    </>
  )
}

export default AddCredits; 