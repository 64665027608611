// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignIn from './SignIn/SignIn';
import LandingPage from './components/landingPage';
import RazorpayPayment from './Checkout/RazorpayPayment'
import RazorpayButton from './Checkout/RazorPayButton'
import ProtectedRoute from './protectedRoute';
import GenerateInterior from './apps/generateInterior'
import LogoAnywhere from './apps/LogoAnywhere'
import RemoveBG from './apps/removebg';
import { AuthProvider } from './AuthProvider';
import Footer from './components/footer'
import Header from './components/header'
import TandC from './components/TermsAndConditions'
import Myaccount from './components/Myaccount'
import BillingHistory from './components/BillingHistory'
import AddCredits from './components/addCredits'

function App() {
  return (
    <>
    <div className="min-h-screen min-w-full flex flex-col" 
         style={{
           background: 'linear-gradient(135deg, #f8f9fa 0%, #e3f2fd 30%, #f3e5f5 70%, #fff3e0 100%)',
           backdropFilter: 'blur(8px)'
           //        background: 'linear-gradient(135deg, #ffffff 0%, #f0f4f8 25%, #edf2ff 50%, #f0f7ff 75%, #fff5f7 100%)',
           // boxShadow: 'inset 0 0 100px rgba(0,0,0,0.03)'
         }}>
    <div className="flex-grow">
    <AuthProvider>
      <Router>
      <Header></Header>
        <Routes>
          <Route path="/" element={<SignIn />} />
          <Route path="/landing"element={<ProtectedRoute><LandingPage /></ProtectedRoute>}/>
          <Route path="/payment"element={<ProtectedRoute><RazorpayPayment /></ProtectedRoute>}/>
          <Route path="/payButton"element={<ProtectedRoute><RazorpayButton /></ProtectedRoute>}/>
          <Route path="/upload"element={<ProtectedRoute><GenerateInterior /></ProtectedRoute>}/>
          <Route path="/removebg"element={<ProtectedRoute><RemoveBG /></ProtectedRoute>}/>
          <Route path="/terms"element={<ProtectedRoute><TandC /></ProtectedRoute>}/>
          <Route path="/account"element={<ProtectedRoute><Myaccount /></ProtectedRoute>}/>
          <Route path="/billing"element={<ProtectedRoute><BillingHistory /></ProtectedRoute>}/>
          <Route path="/logoanywhere"element={<ProtectedRoute><LogoAnywhere /></ProtectedRoute>}/>
          <Route path="/addCredits"element={<ProtectedRoute><AddCredits /></ProtectedRoute>}/>
        </Routes>
      </Router>
    </AuthProvider>
    </div>
    </div>
    <Footer></Footer>
    </>
  );
}

export default App;
