import React, { useState } from "react";
import FileUploadComponent from "../components/Fileuploader";
import { MagicWand } from "phosphor-react";
import DownloadImage from '../components/downloadImage'
import { useAuth } from '../AuthProvider';
import { deductCredits, getUserCredits } from '../firebase/creditService';

const service1BaseUrl = process.env.REACT_APP_API_SERVICE_53;

const RemoveBG = () => {
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const [loading, setLoading] = useState(false);
  const { currentUser, setCredits } = useAuth();

  const handleGenerate = async () => {
    if (!image) {
      alert("Please upload an image!");
      return;
    }

    const CREDITS_REQUIRED = 1; // Define credits needed for this operation
    const currentCredits = await getUserCredits(currentUser.uid);
    
    if (currentCredits < CREDITS_REQUIRED) {
      alert("Insufficient credits! Please add more credits to continue.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${service1BaseUrl}/api/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image: image,
          app_type: "removebg-v1",
        }),
      });

      const data = await response.json();
      
      // If image generation was successful, deduct credits
      if (data.output) {
        await deductCredits(currentUser.uid, CREDITS_REQUIRED);
        setOutput(data.output);
        const newCredits = await getUserCredits(currentUser.uid);
        setCredits(newCredits);
      }
    } catch (error) {
      console.error("Error generating image:", error);
      alert("Something went wrong while generating the image.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row p-4">
      <div className="w-full md:w-1/2 p-6 flex flex-col items-center">
        <div className="w-full max-w-2xl">
          <h1 className="text-2xl font-bold mb-4 text-gray-800 text-center">Remove Background</h1>
          <FileUploadComponent
            onFileSelect={setImage}
            label="Upload an Image:"
          />
          <div className="flex justify-center mt-4">
            <button className={`px-6 py-2 pt-3 font-semibold rounded-md shadow-md flex flex-row transition ${
              image ? "bg-blue-600 text-white hover:bg-blue-700" : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            onClick={handleGenerate}
            disabled={!image}>
              Remove Background
              <MagicWand size={20} color="#ffffff" weight="thin" className="mx-1 my-1"/>
            </button>
          </div>
        </div>
      </div>
      
      <div className="hidden md:block w-px bg-gray-200 mx-4"></div>
      
      <div className="w-full md:w-1/2 flex flex-col p-6">
       {output && (
            <DownloadImage imageUrl={output} imageName={'image'} />
        )}
        {loading ? (
        <div className="flex justify-center">
          <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
        </div>
        ) : output ? (
          <img
            src={output}
            alt="Generated Design"
            className="max-w-full max-h-full border rounded-md shadow-lg"
          />
        ) : (
          <p className="text-gray-600 mt-64 text-center">Your generated design will appear here...</p>
        )}
      </div>
    </div>
  );
};

export default RemoveBG;
