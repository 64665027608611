import React,{useEffect,useState} from "react";
import { auth } from '../firebase/config';
import { useNavigate } from 'react-router-dom';
import { Coins,User } from "phosphor-react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { useAuth } from '../AuthProvider';
import { getUserCredits, initializeUserCredits } from "../firebase/creditService";
import creditsService from '../firebase/globalCreditsService';


const Header = () => {
  const navigate = useNavigate();
  const [credits, setCredits] = useState(creditsService.getCredits()); // Get initial value
  const { currentUser } = useAuth();

  // getUserCredits(currentUser.uid).then((credits) => {
  //   setCredits(credits);
  // });
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Subscribe to credits updates
    const subscription = creditsService.getCreditsObservable().subscribe((newCredits) => {
      setCredits(newCredits);
    });

    // Cleanup subscription on unmount
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        console.log(creditsService.getCredits(),"header");
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const goToCredits =()=>{
    navigate('./account')
  }

  const goToHome = () => {
    navigate('./landing')
  }

   const goToAccount = () => {
     navigate('./account')
   }

    return (
    <div>
      <div className="p-1 flex justify-between">
        <img src = "./assets/Mylogo.svg" className="max-h-fit mt-3	max-h-fit	h-16 w-32 hover:cursor-pointer" onClick={goToHome} alt="Flowbite Logo"/>
        {user && (
        <div className="flex">
          <div className="flex p-1 pt-3 hover:cursor-pointer hover:opacity-80" onClick={goToCredits}>
            <Coins size={40} color="#2563eb" weight="thin" />
            <div className="text-gray-800 font-bold pt-2 pl-2">{credits}</div>
          </div>
          <img className="rounded-full mb-6 h-12 w-12 m-2 hover:cursor-pointer" src={user?.photoURL ? user.photoURL : "./assets/user.svg"} alt="./assets/user.svg" onClick={goToAccount}/>
        </div>
        )}
      </div>
    </div>
    );
  }

export default Header;