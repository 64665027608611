// src/SignIn.js
import { auth, googleProvider } from '../firebase/config';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './SignIn.css'; // Import the CSS file for styling
import { GoogleLogo,AppleLogo } from "phosphor-react";
import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';


const SignIn = () => {
   const navigate = useNavigate();
  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
       navigate('/landing');
    } catch (error) {
      console.error(error);
    }
  };

   const [currentUser, setCurrentUser] = useState(null);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
     const unsubscribe = onAuthStateChanged(auth, (user) => {
       setCurrentUser(user);
       if (user) {
         navigate('/landing');
       }
       setLoading(false);
     });
     return unsubscribe;
   }, []);


  const signInWithApple = async () => {
    // try {
    //   await signInWithPopup(auth, appleProvider);
    //   console.log("Signed in with Apple!");
    // } catch (error) {
    //   console.error(error);
    // }
  };

  return (
   <div className="signin-container">
      <div className="logo">
         {/* <img src = "./assets/Mylogo.svg" className="h-16 w-32 p-1" alt="Flowbite Logo"/> */}
        {/* <div className="text-black">BareMin</div> */}
      </div>
      <div className="signin-box mt-8 bg-slate-100">
        <h2>Welcome to BareMin</h2>
        <p>Don't have an account? Sign up for free</p>
         <button className="signin-button bg-slate-100 hover:bg-slate-300" onClick={signInWithGoogle}>
          <GoogleLogo size={32} weight="bold" style={{ marginRight: '10px' }} />
          Sign in with Google
        </button>
        {/* <button className="signin-button apple-button" onClick={signInWithApple}>
         <AppleLogo size={32} style={{ marginRight: '12px',marginLeft: '-10px' }}/>
          Sign in with Apple
        </button> */}
      </div>
    </div>
  );
};

export default SignIn;
