import React, { useEffect, useState } from "react";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../firebase/config";
import { useNavigate } from 'react-router-dom';
import { getUserCredits } from "../firebase/creditService";
import  AddCredits  from '../components/addCredits'


const Myaccount = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [credits, setCredits] = useState(0);
  const [addCreditsClicked, setAddCreditsClicked] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log("currentUser: ", currentUser);
      setUser(currentUser);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  useEffect(() => {
    if (user) {
      getUserCredits(user.uid).then((credits) => {
        setCredits(credits);
      });
    }else{
      console.log("No user is signed in.");
    }
  }, [user]);


   const handleLogout = async () => {
     try {
       await signOut(auth);
       navigate('/'); // Redirect to SignIn page after logging out
     } catch (error) {
       console.error("Error logging out: ", error);
     }
   };

   const addCredits = () =>{
    setAddCreditsClicked(true);
   }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white text-gray-800 p-6 rounded-lg max-w-md mx-auto mt-10 shadow-md">
        { !addCreditsClicked ?(
         <>
          <div className="flex items-center justify-between">
          <div className="flex items-center">
            <img className="rounded-full w-16 h-16" src={user?.photoURL} alt="User" />
            <div className="ml-4">
              <div className="text-3xl uppercase">{user?.displayName}</div>
              <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2" onClick={addCredits}>
                Add credits
              </button>
            </div>
          </div>
          </div>
          {user ? (
            <>
            <div className="border-t border-gray-200 pt-4 space-y-4">
              <div className="flex justify-between">
                <span>Email</span>
                <span className="font-medium">{user?.email}</span>
              </div>
              <div className="flex justify-between">
                <span>Credits</span>
                <span className="font-medium"> {credits} </span>
              </div>
              <div className="flex justify-between">
                <span>Account type</span>
                <span className="font-medium">Free</span>
              </div>
              <div className="flex justify-between">
                <span>Billing Status</span>
                <span className="text-red-600 font-medium">Cancelled</span>
              </div>
              <div className="flex justify-between">
                <span>Plan valid until</span>
                <span className="font-medium">--</span>
              </div>
              <div className="flex justify-end">
                <button type="button" className="right bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleLogout}>Logout</button>
              </div>
            </div>
            </>
          ) : (
            <p className="text-center text-gray-400">No user is signed in.</p>
          )}
          </>
        ) : (
          <>
          <AddCredits></AddCredits>
          </>
        )}
      </div>
    </div>
  );
};

export default Myaccount;
