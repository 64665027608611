import React from 'react'

const DownloadImage=({ imageSrc, imageName })=>{

   const downloadImage = async (imageSrc, imageName) => {
    try {
      // Fetch the image as a blob
      const response = await fetch(imageSrc);
      const buffer = await response.arrayBuffer(); // Convert response to an ArrayBuffer
      const blob = new Blob([buffer], { type: "image/jpeg" }); // Create a blob of type JPEG

      // Create a temporary link to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob); // Create a URL for the blob
      link.download = imageName; // Set the downloaded file name
      document.body.appendChild(link); // Append the link to the DOM
      link.click(); // Trigger the click event to download the file
      document.body.removeChild(link); // Clean up by removing the link
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };


return(
  <>
  <div className="flex justify-end mb-8">
    <div>
      <button
        onClick={() => downloadImage('https://replicate.delivery/pbxt/Fc0ijT4WykYXONqrRyR7xNGTXEmY130kiaVYfffeBfhcGZf8E/out.png','image')}
        className="bg-black text-white px-4 py-2 rounded-lg shadow hover:bg-slate-600 transition duration-300">
        Download Image
      </button>
    </div>
  </div>
  </>
)
}

export default DownloadImage