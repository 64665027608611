import { initializeApp } from "firebase/app";
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDzbrzlMqOYcu4O2xsmhFSb3bC3YhhV3K8",
  authDomain: "aisuitapps.firebaseapp.com",
  projectId: "aisuitapps",
  storageBucket: "aisuitapps.firebasestorage.app",
  messagingSenderId: "927638321759",
  appId: "1:927638321759:web:12be23cc21c444a90739ea",
  measurementId: "G-DZ71RK9LQZ"
};

// Initialize Firebase only once
let app;
let db;
let auth;
let googleProvider;

try {
  app = initializeApp(firebaseConfig);
  db = getFirestore(app);
  auth = getAuth(app);
  googleProvider = new GoogleAuthProvider();
  
  // Enable auth persistence
  setPersistence(auth, browserLocalPersistence);

} catch (error) {
  console.error("Firebase initialization error:", error);
}

export { auth, db, googleProvider };
