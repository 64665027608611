import React, { useState } from 'react';
import { useAuth } from '../AuthProvider';
import { addCredits } from '../firebase/creditService';

const serviceBaseUrl = process.env.REACT_APP_API_SERVICE_54;
function RazorpayPayment() {
  const [amount, setAmount] = useState('');
  const { currentUser, setCredits } = useAuth();

  // Calculate credits based on amount (example: 1 INR = 1 credit)
  const calculateCredits = (amount) => {
    return parseInt(amount);
  };

  const handlePaymentSuccess = async (response) => {
    const creditsToAdd = calculateCredits(amount);
    const newCredits = await addCredits(currentUser.uid, creditsToAdd);
    setCredits(newCredits);
    alert('Payment successful! Credits added to your account.');
  };

  // Load Razorpay script
  const loadRazorpayScript = () => {
    const script = document.createElement('script');
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => {
      console.log('Razorpay SDK loaded');
    };
    script.onerror = () => {
      alert('Failed to load Razorpay SDK. Please check your internet connection.');
    };
    document.body.appendChild(script);
  };

  // This function triggers the Razorpay payment flow
  const payNow = async () => {
    // Replace with your backend API call to create an order
    const response = await fetch(`${serviceBaseUrl}/create-order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ amount: amount * 1, currency: 'INR', receipt: 'receipt#1' })
    });

    const order = await response.json();

    const options = {
      key: 'rzp_live_MnW8FvAZYjHUyz', // Replace with your actual Razorpay key_id
      amount: amount * 1, // Convert amount to subunits (paise)
      currency: 'INR',
      name: 'Your Company Name',
      description: 'Test Transaction',
      order_id: order.id, // Get order_id from the backend response
      // callback_url: 'http://localhost:3000/SignIn', // Your success URL
      prefill: {
        name: 'John Doe',
        email: 'john.doe@example.com',
        contact: '9999999999'
      },
      theme: {
        color: '#3399cc'
      },
      handler: function (response) {
        handlePaymentSuccess(response);
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  // Load Razorpay script when the component mounts
  React.useEffect(() => {
    loadRazorpayScript();
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Razorpay Payment Gateway Integration button</h1>
      <form id="payment-form" onSubmit={(e) => e.preventDefault()}>
        <label htmlFor="amount">Amount:</label>
        <input
          type="number"
          id="amount"
          name="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
        <button type="button" onClick={payNow}>Pay Now</button>
      </form>
    </div>
  );
}

export default RazorpayPayment;
