import { doc, getDoc, setDoc, updateDoc, runTransaction } from 'firebase/firestore';
import { db } from './config';
import creditsService from './globalCreditsService';

// Initialize user credits
export const initializeUserCredits = async (userId) => {
  const userRef = doc(db, 'users', userId);

  try {
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
      await setDoc(userRef, {
        credits: 0,
        lastUpdated: new Date().toISOString(),
      });
      creditsService.setCredits(0); // Initialize in CreditsService
    } else {
      const data = userDoc.data();
      creditsService.setCredits(data.credits); // Sync with Firestore
    }
    return true;
  } catch (error) {
    console.error('Error initializing credits:', error);
    return false;
  }
};

// Get user credits
export const getUserCredits = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const credits = userDoc.data().credits;
      creditsService.setCredits(credits); // Update the global reference
      return credits;
    }

    // Initialize user credits if not found
    let res = await initializeUserCredits(userId);
    if (res) {
      return 0;
    }

    return 0;
  } catch (error) {
    console.error('Error getting credits:', error);
    return 0;
  }
};

// Add credits using transaction
export const addCredits = async (userId, amount) => {
  try {
    const userRef = doc(db, 'users', userId);
    await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userRef);
      if (!userDoc.exists()) {
        throw new Error('User document does not exist!');
      }

      const newCredits = userDoc.data().credits + amount;
      transaction.update(userRef, {
        credits: newCredits,
        lastUpdated: new Date().toISOString(),
      });

      creditsService.setCredits(newCredits); // Update the global reference
    });
    return true;
  } catch (error) {
    console.error('Error adding credits:', error);
    return false;
  }
};

// Deduct credits using transaction
export const deductCredits = async (userId, amount) => {
  try {
    const userRef = doc(db, 'users', userId);
    return await runTransaction(db, async (transaction) => {
      const userDoc = await transaction.get(userRef);
      if (!userDoc.exists()) {
        return false;
      }

      const currentCredits = userDoc.data().credits;
      if (currentCredits < amount) {
        return false;
      }

      const newCredits = currentCredits - amount;
      transaction.update(userRef, {
        credits: newCredits,
        lastUpdated: new Date().toISOString(),
      });

      creditsService.setCredits(newCredits); // Update the global reference
      return true;
    });
  } catch (error) {
    console.error('Error deducting credits:', error);
    return false;
  }
};
