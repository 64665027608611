import React, { useState} from "react";
import { FileUploader } from "react-drag-drop-files";
import './FileUploaderStyles.css'
import { CloudArrowUp } from "phosphor-react";

const FileUploadComponent = ({ onFileSelect, fileTypes }) => {
  const [preview, setPreview] = useState(null);

  const handleFileDrop = (file) => {
    if (!file) {
      alert("Please select an image to upload!");
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      onFileSelect(event.target.result.split(",")[1]); // Send Base64 data to parent
      setPreview(URL.createObjectURL(file)); // Create preview URL
    };
    reader.readAsDataURL(file); // Read file as Base64
  };

  return (
    <div className="flex flex-col items-center justify-center">
         <div className="FileUploaderClass bg-gray-50 rounded-lg shadow-lg w-full mx-auto">
          <div className="container">
           <div className="icon">
            <CloudArrowUp size={60} color="#ffffff" />
           </div>
           <FileUploader
            handleChange={handleFileDrop}
            name="file"
            types={fileTypes}
            multiple={false}
           />
          </div>
          </div>
      {preview && (
        <div className="mt-6 mb-6">
          <h2 className="text-sm font-semibold text-gray-500 mb-2">Preview:</h2>
          <img
            src={preview}
            alt="Preview"
            className="w-full rounded-md border border-gray-200 shadow-md"
          />
        </div>
      )}
    </div>
  );
};

FileUploadComponent.defaultProps = {
  fileTypes: ["PNG", "JPG", "WEBP","JPEG"],
};

export default FileUploadComponent;