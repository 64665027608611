import React, { useState } from "react";
import FileUploadComponent from "../components/Fileuploader";
import { MagicWand } from "phosphor-react";
import DownloadImage from '../components/downloadImage'

const service1BaseUrl = process.env.REACT_APP_API_SERVICE_53;
const GenerateInterior = () => {
  const [prompt, setPrompt] = useState("");
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleGenerate = async () => {
    if (!prompt || !image) {
      alert("Please provide a prompt and upload an image!");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${service1BaseUrl}/api/generate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: prompt,
          image: image,
          app_type: "interiorai-v1",
          max_resolution: 1051,
          controlnet_conditioning_scale: 0.03,
        }),
      });

      const data = await response.json();
      setOutput(data.output);
    } catch (error) {
      console.error("Error generating image:", error);
      alert("Something went wrong while generating the image.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row p-4">
      <div className="w-full md:w-1/2 p-6 flex flex-col items-center">
        <div className="w-full max-w-2xl">
          <h1 className="text-2xl font-bold mb-4 text-gray-800 text-center">Interior Designer</h1>
          <FileUploadComponent
            onFileSelect={setImage}
            label="Upload an Image:"
          />
          <textarea
            className="w-full p-4 mb-4 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
            rows="1"
            placeholder="Enter your design prompt..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
          <div className="flex justify-center">
            <button className={`px-6 py-2 pt-3 font-semibold rounded-md shadow-md flex flex-row transition ${
              image && prompt.trim() ? "bg-blue-600 text-white hover:bg-blue-700" : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            onClick={handleGenerate}
            disabled={!prompt.trim() && !image}>
              Generate
              <MagicWand size={20} color="#ffffff" weight="thin" className="mx-1 my-1"/>
            </button>
          </div>
        </div>
      </div>
      
      <div className="hidden md:block w-px bg-gray-200 mx-4"></div>
      
      <div className="w-full md:w-1/2 flex flex-col p-6">        
        <div>
            {output && (
            <DownloadImage imageUrl={output} imageName={'image'} />
        )}
        </div>
        <div>
          {loading ? (
          <div className="flex justify-center">
            <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
          </div>) : output ? (
          <img
            src={output}
            alt="Generated Design"
            className="max-w-full max-h-full border rounded-md shadow-lg"/>
          ) : (
          <p className="text-gray-600 mt-64 text-center">Your generated design will appear here...</p>
        )}
        </div>
      </div>
    </div>
  );
};

export default GenerateInterior;
