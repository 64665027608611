// globalCreditsService.js
import {
  BehaviorSubject
} from 'rxjs';

class CreditsService {
  constructor() {
    this.credits$ = new BehaviorSubject(0); // Initial value
  }

  // Get current credits as observable
  getCreditsObservable() {
    return this.credits$.asObservable();
  }

  // Get current value of credits
  getCredits() {
    return this.credits$.getValue();
  }

  // Set new credits
  setCredits(newCredits) {
    this.credits$.next(newCredits);
  }
}

const creditsService = new CreditsService();
export default creditsService;
