import React from "react";


const LogoAnywhere = () => {

  return (
  <>
  <div className="text-white">Your log anyhere</div>
  </>
  )
};

export default LogoAnywhere;
