// src/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();

  // Redirect to the SignIn page if the user is not authenticated
  return currentUser ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
